import employeeDirectory from "../images/employee-directory.png";
import petPandemonium from "../images/pet-pandemonium.png";
import pocket from "../images/pocket.png";
import puppyParty from "../images/puppy-party.png";
import quizTime from "../images/quiz-time.png";
import weatherTravel from "../images/weather-travel.png";

const portfolioData = [
  {
    name: "Pocket Bar Book",
    description:
      "A Next JS, Apollo GraphQL, Nest JS, PostgresQL cocktail recipe sharing appliation using Typescript with Next-Auth and Prisma.",
    image: pocket,
    githubLink: "https://github.com/jacob-af/employee-directory",
    deployedLink: "https://pocketbarbook.com"
  },
  {
    name: "Pet Pandemonium",
    description:
      "MERN stack multiplayer Battleship style game using functional components, hooks, Socket.io, and Howler.js deployed to Heroku",
    image: petPandemonium,
    githubLink: "https://github.com/jacob-af/pet-pandemonium",
    deployedLink: "https://pet-pandemonium.onrender.com"
  },
  {
    name: "Employee Directory",
    description:
      "A React based application for filtering and sorting employee data generated by the Random User API",
    image: employeeDirectory,
    githubLink: "https://github.com/jacob-af/employee-directory",
    deployedLink: "https://jacobfeitler.com/employee-directory/"
  },
  {
    name: "Weather Travel",
    description: "A colorful weather dashboard using the Open Weather API.",
    image: weatherTravel,
    githubLink: "https://github.com/jacob-af/weather-travel",
    deployedLink: "https://www.jacobfeitler.com/weather-travel/"
  },
  {
    name: "Puppy Party",
    description:
      "Schedule all your trips to the Dog Park using third party APIs, jQuery, and Materialze.css",
    image: puppyParty,
    githubLink: "https://github.com/jacob-af/Puppy-Party-Planner",
    deployedLink: "https://www.jacobfeitler.com/Puppy-Party-Planner/"
  },
  {
    name: "Quiz Time",
    description:
      "Timed quiz covering some JavaScript basics using jQuery and Bootstrap",
    image: quizTime,
    githubLink: "https://github.com/jacob-af/quiz-time",
    deployedLink: "https://www.jacobfeitler.com/quiz-time/"
  }
];

export default portfolioData;
